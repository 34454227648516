@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-Regular.ttf");
}

html {
  min-height: 100vh;
  scroll-behavior:smooth
}

#root {
  min-height: 100vh
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.5rem
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 900
}

h3 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
}

h4 {
  margin: 0;
  font-size: 1rem
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.width-100 {
  width: 100%;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.no-border {
  border: 0
}

.pb-1 {
  padding-bottom: 1em
}

.bold {
  font-weight: bold
}

.gap-1 {
  gap: 1rem
}

.gap-small {
  gap: 0.2rem
}

.gap-medium {
  gap: 0.5rem
}

.font-small {
  font-size: 10px
}

.text-align-center {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.max-width {
  width: 100%;
}

.ml-1 {
  margin-left: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

table {
  border-collapse: collapse;
}

th {
  border: 1px solid #d9d9d9;
  padding: 1.5em 1em 4em 1em
}

td {
  border: 1px solid #d9d9d9;
  padding: 2.5em 1em 2.5em 1em;
  border-collapse: collapse
}

.darkModeScroll::-webkit-scrollbar {
  width: 14px;
  border-radius: 7px;
}

.darkModeScroll::-webkit-scrollbar-track {
  background: #484464;
  border-radius: 7px
}

.darkModeScroll::-webkit-scrollbar-thumb {
  background: #865aa5;
}

.darkModeScroll::-webkit-scrollbar-thumb:hover {
  background: #865aa5;
}

::-webkit-scrollbar {
  width: 14px;
  border-radius: 7px;
}

::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 7px
}

::-webkit-scrollbar-thumb {
  background: #cdaed3;
  border-radius: 7px
}

::-webkit-scrollbar-thumb:hover {
  background: #cdaed3;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}